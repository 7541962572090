function sell_back_to_chards_response(popup_contents) {
    var form = $('#popup-sell-back-to-chards form'),
    response = $.parseJSON(popup_contents.response);

    $.each(form.find('tbody tr'), function() {
        $(this).hide();
    });
    $.each(response, function(index, value) {
        var row = form.find('tr[data-name="' + index + '"]');
        if (row.length) {
            var price = value.sell_price;

            if (price > 0) {
                row.show();
                var formatter = new Intl.NumberFormat('en-GB', {
                    style: 'currency',
                    currency: 'GBP',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                });

                row.find('input[data-calc="weight"]').attr('max', value.sell_weight);
                row.find('.max.weight span').text(value.sell_weight);
                row.find('.max.price span').text(formatter.format(price));
            }
        }
    });
}

export {sell_back_to_chards_response}